import { format } from '@formkit/tempo'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

/**
 * Converts date to format provided as an argument or defined in config file (if argument not provided)
 * @param {String} date
 * @param {String} format
 */
export function date (date, formatStr, storeView) {
  if (!date) return ''

  const _storeView = storeView || currentStoreView()
  const locale = _storeView.i18n.defaultLocale

  try {
    return format({
      date: new Date(date),
      format: formatStr || 'DD MMM, ddd',
      locale
    })
  } catch (e) {
    console.warn('Date formatting error:', e)
    return date
  }
}
